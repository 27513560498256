import React, { useState, useEffect, Fragment } from 'react'
import './UserList.scss'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import { deleteUserData, getAllUsersData, editUserListData } from '../../helper/helper';
import ReadOnlyUserList from '../../components/ReadOnlyUserList'
import EditableUserList from '../../components/EditableUserList'
import toast, { Toaster } from 'react-hot-toast';

const UserList = () => {

  const [userdata, setUserdata] = useState([])
  const [editEmployeeId, setEditEmployeeId] = useState(null)
  const [editFormData, setEditFormData] = useState({
    fullname: '',
    username: '',
    password: '',
    email: '',
    role: '',
    phonenumber: '',
    employeeid: '',
    stores: '',
    dob: '',
  })

  useEffect(() => {
    getRawUserData();

  }, [])
  async function getRawUserData() {
    try {
      let { data } = await getAllUsersData();
      setUserdata(data);

    } catch (error) {
      return error
    }
  }

  const handleEditEmployee = (event, userdata) => {
    event.preventDefault();
    setEditEmployeeId(userdata._id);
    const formValues = {
      fullname: userdata.fullname,
      username: userdata.username,
      password: userdata.password,
      email: userdata.email,
      role: userdata.role,
      phonenumber: userdata.phonenumber,
      employeeid: userdata.employeeid,
      stores: userdata.stores,
      dob: userdata.dob
    }
    setEditFormData(formValues)
  }

  const handleDeleteClick = (UserId) => {
    const newUsers = [...userdata];
    const index = newUsers.findIndex(user => user._id === UserId);
    let user = newUsers.splice(index, 1)

    if (window.confirm(`Are you sure you want to delete ${user[0].fullname}'s data?`)) {
      const deleteUsersPromise = deleteUserData({ id: UserId });
      toast.promise(deleteUsersPromise, {
        loading: 'Deleting...',
        success: <b>Deleted Successfully...!</b>,
        error: <b>Could not Delete.</b>
      }).then(() => {
        getRawUserData();

      });
      setUserdata(newUsers)

    } else {
      window.alert("not done");
    }


  }

  const handleCancelClick = () => {
    setEditEmployeeId(null);
  }

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    let fieldValue = '';
    if (fieldName === "stores") {
      let list = Array.from(event.target.selectedOptions, (option) => option.value)
      const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
      }
      const selectedKeys = Object.keys(storeNameList).filter(
        (key) => list.includes(storeNameList[key])
      );
      list = selectedKeys.join(',')
      fieldValue = list
    } else {
      fieldValue = event.target.value;
    }


    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData)
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editUsers = {
      _id: editEmployeeId,
      fullname: editFormData.fullname,
      username: editFormData.username,
      password: editFormData.password,
      email: editFormData.email,
      role: editFormData.role,
      phonenumber: editFormData.phonenumber,
      employeeid: editFormData.employeeid,
      stores: editFormData.stores,
      dob: editFormData.dob
    }
    const newUsers = [...userdata]
    const index = userdata.findIndex((user) => user._id === editEmployeeId);
    const editRow = newUsers[index] = editUsers;

    const editRowPromise = editUserListData({
      _id: editRow._id,
      fullname: editRow.fullname,
      username: editRow.username,
      password: editRow.password,
      email: editRow.email,
      role: editRow.role,
      phonenumber: editRow.phonenumber,
      employeeid: editRow.employeeid,
      stores: editRow.stores,
      dob: editRow.dob
    })
    toast.promise(editRowPromise, {
      loading: 'Editing...',
      success: <b>Editied Successfully...!</b>,
      error: <b>Could not Edit.</b>
    }).then(() => {
      getRawUserData();

    })
    setUserdata(newUsers);
    setEditEmployeeId(null);
  }


  return (
    <div className='list'>
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <AdminSidebar />
      <div className="listContainer">
        <div className="navbarcontainer">
          <Navbar />
        </div>
        {/*  <div className="tablecontainer" >*/}

        <div className="bottomtable">
          <form onSubmit={handleEditFormSubmit}>
              <table className="table-auto">
                <thead>
                  <tr>
                    <th >Name</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Phone No.</th>
                    <th>Emp/Id</th>
                    <th>Stores</th>
                    <th>DOB</th>
                    <th className="actions" rowSpan="2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userdata.map((userdata, index) => (
                    <Fragment key={index}>
                      {editEmployeeId === userdata._id ?
                        (<EditableUserList
                          editFormData={editFormData}
                          key={userdata}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick} />)
                        : (
                          <ReadOnlyUserList
                            userdata={userdata}
                            key={userdata}
                            handleEditEmployee={handleEditEmployee}
                            handleDeleteClick={handleDeleteClick}
                          />)}
                    </Fragment>
                  ))}
                </tbody>
              </table>
              
            </form>
          
          </div>
        {/* </div>*/}
      </div>
    </div>
  )
}

export default UserList