import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { useFormik } from 'formik'
/* import { usernameValidate } from '../../helper/validate' */
import { useAuthStore } from '../../store/store'
import { verifyPassword, getUser } from '../../helper/helper'
import logo from '../../assets/logo.png'
import './Login.scss'

const Login = () => {

    const setUsername = useAuthStore(state => state.setUsername);
    const navigate = useNavigate();
    const [focusedInput, setFocusedInput] = useState(null);

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput(null);
    };
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        /* validate: usernameValidate, */
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async values => {
            setUsername(values.username);
            if (!values.username || !values.password) {
                toast.error("Please enter your credentials.");
            } else {
                localStorage.setItem('username', values.username);
                let loginPromise = verifyPassword({ username: values.username, password: values.password })
                let data = getUser({ username: values.username });

                toast.promise(loginPromise, {
                    loading: 'Checking...',
                    success: <b>Login Successfully...!</b>,
                    error: <b>Username or Password does not Match!</b>
                },
                    {
                        loading: {
                            id: 'wait',
                            duration: 1
                        },
                        success: {
                            id: 'match',
                            duration: 2000
                        },
                        error: {
                            id: 'unmatch',
                            duration: 2000
                        }
                    });

                loginPromise.then(res => {
                    let { token } = res.data;
                    localStorage.setItem('token', token);

                    data.then(res => {
                        let apiData = res.data;
                        if (apiData.firstLogin === "true") {
                            navigate("/recovery")
                        } else {
                            localStorage.setItem('role', apiData.role)
                            if (apiData.role === 'employee') {
                                navigate('/employee')
                            } else if (apiData.role === 'admin') {
                                navigate('/admin')
                            } else if (apiData.role === 'manager') {
                                navigate('/manager')
                                toast.promise(loginPromise, {
                                    loading: 'Checking...',
                                    success: <b>Login Successfully...!</b>,
                                    error: <b>Username or Password does not Match!</b>
                                },
                                    {
                                        loading: {
                                            id: 'wait',
                                            duration: 1
                                        },
                                        success: {
                                            id: 'match',
                                            duration: 2000
                                        },
                                        error: {
                                            id: 'unmatch',
                                            duration: 2000
                                        }
                                    });

                                loginPromise.then(res => {
                                    let { token } = res.data;
                                    localStorage.setItem('token', token);

                                    data.then(res => {
                                        let apiData = res.data;
                                        if (apiData.firstLogin === "true") {
                                            navigate("/recovery")
                                        } else {
                                            if (apiData.role === 'employee') {
                                                navigate('/employee')
                                            } else if (apiData.role === 'admin') {
                                                navigate('/admin')
                                            } else if (apiData.role === 'manager') {
                                                navigate('/manager')
                                            }
                                        }

                                    })
                                }
                                )
                            }
                        }
                    })
                })
            }
        }
    })
    return (
        <div className='container'>
            <Toaster position='top-center' reverseOrder={false}></Toaster>

            <div className="left">
                <div className="overlay">
                    <div className='bot'>
                        <h1>VFLOW</h1>
                        <div className='logo'>
                            <img src={logo} alt="logo" />
                            <div className='footer'>
                                <p>Copyright © 2010 - 2024 Vyadom Group. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form className="right" onSubmit={formik.handleSubmit}>
                <div className='main'>
                    <h3 className='heading'>Login</h3>
                    <div className="inputs">
                        <label>Email</label>
                        <input {...formik.getFieldProps('username')}
                            onFocus={() => handleFocus('username')}
                            style={{ borderColor: focusedInput === 'username' ? 'green' : 'rgb(99 102 241)' }}
                            type="text"
                            onBlur={handleBlur}
                            placeholder="Username" />
                        <label>Password</label>
                        <input {...formik.getFieldProps('password')}
                            onFocus={() => handleFocus('password')}
                            style={{ borderColor: focusedInput === 'password' ? 'green' : 'rgb(99 102 241)' }}
                            type="password"
                            onBlur={handleBlur}
                            placeholder="Password" />
                    </div>
                    <div className="remember-me--forget-password">
                        <p> <Link className='recover' to="/recovery">Forgot Password?</Link></p>
                    </div>
                    <div className='submitbutton'>
                        <button type='submit'>Login</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login