import React from 'react'

const EditableUserList = ({ editFormData, handleEditFormChange, handleCancelClick }) => {
    const storesList = ["Lake Vista Plaza", "Lakeshore & Islington", "Center Mall Barton", "Town Center Plaza", "Cedarbrae Mall", "Barrie", "Rexdale & Islington", "Briarfield Shopping Centre",
        "Danforth & Woodbine", "Big Bay", "Markham & Lawrence", "Weston & Lawrence",]
    const rolesList = ["employee", "manager", "admin"]

    return (
        <tr>
            <td><input
                type="text"
                name="fullname"
                required="required"
                placeholder="Full Name"
                value={editFormData.fullname}
                onChange={handleEditFormChange}
            /></td>
            <td><input
                type="text"
                name="username"
                required="required"
                placeholder="User Name"
                value={editFormData.username}
                onChange={handleEditFormChange}
            /></td>
            <td><input
                type="password"
                name="password"
                required="required"
                placeholder="Password"
                value={editFormData.password}
                onChange={handleEditFormChange}
            /></td>
            <td><input
                type="email"
                name="email"
                required="required"
                placeholder="Email"
                value={editFormData.email}
                onChange={handleEditFormChange}
            /></td>
            <td>
                <select multiple className='forminput-select' name='role' onChange={handleEditFormChange} >
                    {rolesList.map((role) => (
                        <option key={role} value={role} >
                            {role}
                        </option>
                    ))}
                </select>
            </td>
            {/* <td><input
                type="text"
                name="role"
                required="required"
                placeholder="role"
                value={editFormData.role}
                onChange={handleEditFormChange}
            /></td> */}
            <td><input
                type="number"
                name="phonenumber"
                required="required"
                placeholder="Phonenumber"
                value={editFormData.phonenumber}
                onChange={handleEditFormChange}
            /></td>
            <td><input
                type="number"
                name="employeeid"
                required="required"
                placeholder="EmployeeId"
                value={editFormData.employeeid}
                onChange={handleEditFormChange}
            /></td>
            <td>
                <select multiple className='forminput-select' name='stores' onChange={handleEditFormChange} >
                    {storesList.map((store) => (
                        <option key={store} value={store} >
                            {store}
                        </option>
                    ))}
                </select>
            </td>
            {/* <td><input
                type="text"
                name="stores"
                required="required"
                placeholder="Stores"
                value={editFormData.stores}
                onChange={handleEditFormChange}
            /></td> */}
            <td><input
                type="text"
                name="dob"
                required="required"
                placeholder="DOB"
                value={editFormData.dob}
                onChange={handleEditFormChange}
            /></td>
            <td>
                <button className='table-btn' type='submit'>SAVE</button>
                <button className='table-btn' type='button' onClick={handleCancelClick}>CANCEL</button>
        </td>
        </tr>
    )
}

export default EditableUserList